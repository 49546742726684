<template>
  <div class="px-3 py-2">
    <v-form ref="forcar" lazy-validation>
      <b-sidebar
        id="add_project"
        aria-labelledby="sidebar-no-header-title"
        no-header
        backdrop
        width="80rem"
        style="direction: ltr"
        right
        title=""
        shadow
      >
        <template #default="{ hide }">
          <div class="m-1 text-start" style="direction: rtl">
            <div
              style="
                width: 120px;
                margin-right: 10px;
                background: #000;
                color: #fff;
                border-radius: 5px;
              "
              class="btn-primary btn-sm text-center"
            >
              <span>{{ lang.add_project }}</span>
            </div>
            <div
              @click="hide"
              id="hideAddProject"
              style="
                width: 100px;
                text-align: center;
                float: left;
                margin-left: 5px;
                margin-top: -25px;
                cursor: pointer;
              "
              class="btn-danger btn-sm"
            >
              <span>{{ lang.close }}</span>
            </div>
          </div>
          <v-divider></v-divider>
          <v-row style="direction: rtl" class="m-2">
            <v-col cols="12" md="4" sm="12">
              <label>{{ lang.project_title }}</label>
                <b-form-input
                  class="inborder"
                  v-model="project_title"
                ></b-form-input>
            </v-col>
            <v-col cols="12" md="4" sm="12">
              <label>{{ lang.project_type }}</label>
              <b-input-group>
                <b-form-select
                  class="selborder"
                  v-model="project_type"
                  :options="projectTypes"
                ></b-form-select>
                <b-input-group-addon style="margin-right: -20px; margin-top: 5px">
                  <i class="fas fa-arrow-down"></i>
                </b-input-group-addon>
              </b-input-group>
            </v-col>
            <v-col cols="12" md="4" sm="12">
                <label>{{ lang.project_owner }}</label>
                <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="project_owner"
                        :options="responses"
                    ></b-form-select>
                    <b-input-group-addon style="margin-right: -20px; margin-top: 5px">
                        <i class="fas fa-arrow-down"></i>
                    </b-input-group-addon>
                </b-input-group>
            </v-col>
            <v-col cols="12" md="4" sm="12">
                <label>{{ lang.project_start }}</label>
                <b-input-group>
                    <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                    <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                    <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
                </b-input-group>
            </v-col>
            <v-col cols="12" md="4" sm="12">
                <label>{{ lang.project_deadline }}</label>
                <b-input-group>
                    <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="dd.day" :options="daysList"/>
                    <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="dd.month" :options="monthsList" />
                    <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="dd.year" :options="yearsList" />
                </b-input-group>
            </v-col>
            <v-col cols="12" md="4" sm="12">
                <label>{{ lang.project_close_date }}</label>
                <b-input-group>
                    <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="cd.day" :options="daysList"/>
                    <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="cd.month" :options="monthsList" />
                    <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="cd.year" :options="yearsList" />
                </b-input-group>
            </v-col>
            <v-col cols="12" md="3" sm="12">
                <label>{{ lang.project_employees }}</label>
                <b-input-group>
                    <b-form-select
                        class="inborder"
                        v-model="project_employees"
                        multiple
                        :options="responses"
                        style="width:100%;"
                    ></b-form-select>
                </b-input-group>
            </v-col>
            <v-col cols="12" md="9" sm="12">
                <div>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th class="backBlack">{{ lang.checklist }}</th>
                                <th class="backBlack" style="width:80px;">{{ lang.action }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in checkList" :key="index">
                                <td>{{ item.description }}</td>
                                <td @click="deleteTask(index,2)" style="text-align:center;background:red !important;color:#fff !important;cursor:pointer;">
                                    {{ lang.delete }}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>
                                    <b-form-input
                                        class="inborder"
                                        v-model="nCheckList.description"
                                        multiple
                                        style="width:100%;"
                                    ></b-form-input>
                                </th>
                                <th @click="addToCheck()" style="text-align:center;background:blue !important;color:#fff !important;cursor:pointer;">
                                    {{ lang.add }}
                                </th>
                            </tr>
                        </tfoot>
                    </v-simple-table>
                </div>
            </v-col>
            <v-col cols="12" md="12" sm="12">
                <label>{{ lang.notes }}</label>
                <b-form-textarea v-model="notes" rows="5"></b-form-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12">
                <div>
                    <v-simple-table>
                        <thead>
                            <tr>
                                <th class="backBlack" style="width:130px;">{{ lang.employee }}</th>
                                <th class="backBlack" style="width:150px;">{{ lang.task_title }}</th>
                                <th class="backBlack">{{ lang.task }}</th>
                                <th class="backBlack" style="width:150px;">{{ lang.exeute_date }}</th>
                                <th class="backBlack" style="width:80px;">{{ lang.action }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in tasks" :key="index">
                                <td>{{ item.employee }}</td>
                                <td>{{ item.title }}</td>
                                <td>{{ item.description }}</td>
                                <td>{{ item.executed_date }}</td>
                                <td @click="deleteTask(index,1)" style="text-align:center;background:red !important;color:#fff !important;cursor:pointer;">
                                    {{ lang.delete }}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>
                                    <b-form-select
                                        class="selborder"
                                        v-model="newTask.employee"
                                        :options="responses"
                                        style="width:100%;"
                                    ></b-form-select>
                                </th>
                                <th>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newTask.title"
                                        style="width:100%;"
                                    ></b-form-input>
                                </th>
                                <th>
                                    <b-form-textarea
                                        class="inborder"
                                        v-model="newTask.description"
                                        style="width:100%;"
                                    ></b-form-textarea>
                                </th>
                                <th>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newTask.executed_date"
                                        style="width:100%;"
                                        type="date"
                                    ></b-form-input>
                                </th>
                                <th @click="addToTask()" style="text-align:center;background:blue !important;color:#fff !important;cursor:pointer;">
                                    {{ lang.add }}
                                </th>
                            </tr>
                        </tfoot>
                    </v-simple-table>
                </div>
            </v-col>
          </v-row>
        </template>
        <template #footer="" class="shadow">
          <div class="d-flex text-light align-items-center px-3 py-2">
            <b-button
              type="button"
              @click="addProject()"
              variant="success"
              class="ma-2"
              style="width: 100px"
              >{{ lang.add }}</b-button
            >
          </div>
        </template>
      </b-sidebar>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
import { SnotifyPosition } from "vue-snotify";
export default {
  data() {
    return {
      project_type: 1,
      project_title: '',
      project_owner: 1,
      project_employees: [],
      sd: {
        day: (new Date()).getDate(),
        month: (new Date()).getMonth() + 1,
        year: (new Date()).getFullYear(),
      },
      dd: {
        day: (new Date()).getDate(),
        month: (new Date()).getMonth() + 1,
        year: (new Date()).getFullYear() ,
      },
      cd: {
            day: (new Date()).getDate(),
            month: (new Date()).getMonth() + 1,
            year: (new Date()).getFullYear(),
      },
      newTask:{
        employee: 1,
        description: '',
        title: '',
        executed_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      },
      tasks:[],
      nCheckList: {
        description: '',
      },
      checkList: [],
      notes: "",
      crm_id: 0,
      task_response: 1,
      users: [],
      status: 1,
      projectsType: [],
      taskDet: [],
      exeute_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      task_priority: 1,
      priorities: [],
    };
  },
  computed: {
    lang: function () {
      if (this.$cookies.get(this.$store.state.LangCooki) != null) {
        if (this.$cookies.get(this.$store.state.LangCooki) == "en") {
          return this.$store.state.lang.en;
        } else {
          return this.$store.state.lang.ar;
        }
      } else if (this.$store.state.deflang == "en") {
        return this.$store.state.lang.en;
      } else {
        return this.$store.state.lang.ar;
      }
    },
    yearsList: function(){
        const start_year = this.$store.state.licenseType.startYear;
        let t = [{text: this.lang.year, value: -1}];
        for(let i=0;i<100;i++){
        if(parseInt(start_year) + i <= (new Date()).getFullYear()){
            t.push({text: start_year + i, value: start_year+i})
        }
        
        }
        return t
    },
    daysList: function(){
        return [
        {text: this.lang.day, value: -1},
        {text: 1, value: 1},
        {text: 2, value: 2},
        {text: 3, value: 3},
        {text: 4, value: 4},
        {text: 5, value: 5},
        {text: 6, value: 6},
        {text: 7, value: 7},
        {text: 8, value: 8},
        {text: 9, value: 9},
        {text: 10, value: 10},
        {text: 11, value: 11},
        {text: 12, value: 12},
        {text: 13, value: 13},
        {text: 14, value: 14},
        {text: 15, value: 15},
        {text: 16, value: 16},
        {text: 17, value: 17},
        {text: 18, value: 18},
        {text: 19, value: 19},
        {text: 20, value: 20},
        {text: 21, value: 21},
        {text: 22, value: 22},
        {text: 23, value: 23},
        {text: 24, value: 24},
        {text: 25, value: 25},
        {text: 26, value: 26},
        {text: 27, value: 27},
        {text: 28, value: 28},
        {text: 29, value: 29},
        {text: 30, value: 30},
        {text: 31, value: 31},
        ]
    },
    monthsList: function(){
        return [
        {text: this.lang.chose_month, value: -1},
        {text: 1, value: 1},
        {text: 2, value: 2},
        {text: 3, value: 3},
        {text: 4, value: 4},
        {text: 5, value: 5},
        {text: 6, value: 6},
        {text: 7, value: 7},
        {text: 8, value: 8},
        {text: 9, value: 9},
        {text: 10, value: 10},
        {text: 11, value: 11},
        {text: 12, value: 12},
        ]
    },
    project_start: function() {
        return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString();
    },
    project_deadline: function() {
        return (new Date(this.dd.year,this.dd.month-1, this.dd.day+1)).toISOString();
    },
    project_closed: function() {
        return (new Date(this.cd.year,this.cd.month-1, this.cd.day+1)).toISOString();
    },
    responses: function () {
      let t = [];
      for (let i = 0; i < this.users.length; i++) {
        t.push({ text: this.users[i].full_name, value: this.users[i].id });
      }
      return t;
    },
    taskStatus: function () {
      let t = [];
      for (let i = 0; i < this.statuss.length; i++) {
        let text =
          this.lang.langname == "ar" ? this.statuss[i].name_ar : this.statuss[i].name_en;
        t.push({ text: text, value: this.statuss[i].value });
      }
      return t;
    },
    projectTypes: function () {
      let t = [];
      for (let i = 0; i < this.projectsType.length; i++) {
        let text =
          this.lang.langname == "ar" ? this.projectsType[i].name_ar : this.projectsType[i].name_en;
        t.push({ text: text, value: this.projectsType[i].value });
      }
      return t;
    },
    taskProiorities: function () {
      let t = [];
      for (let i = 0; i < this.priorities.length; i++) {
        let text =
          this.lang.langname == "ar" ? this.priorities[i].name_ar : this.priorities[i].name_en;
        t.push({ text: text, value: this.priorities[i].value });
      }
      return t;
    },
  },
  methods: {
    addProject(){
        if (this.project_title == "") {
            let message = "يجب اضافة عنوان للمشروع";
            this.$snotify.error(message, "تنبيه", {
            timeout: 1000000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition.centerCenter,
            buttons: [
                {
                text: this.$parent.lang.close,
                action: (toast) => {
                    this.$snotify.remove(toast.id);
                },
                },
            ],
            });
            return false;
        }
        if (this.notes == "") {
            let message = "يجب اضافة ملاحظات المشروع";
            this.$snotify.error(message, "تنبيه", {
            timeout: 1000000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition.centerCenter,
            buttons: [
                {
                text: this.$parent.lang.close,
                action: (toast) => {
                    this.$snotify.remove(toast.id);
                },
                },
            ],
            });
            return false;
        }

        const post = new FormData(); 
        post.append("type","addCrmProject");
        post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
        post.append("data[project_type]",this.project_type)
        post.append("data[project_title]",this.project_title)
        post.append("data[project_owner]",this.project_owner)
        post.append("data[notes]",this.notes)
        post.append("data[status]",this.status)
        
        Object.keys(this.project_employees).forEach(key => {
            post.append('data[employees][]',this.project_employees[key]);
        })
        Object.keys(this.checkList).forEach(key => {
            post.append('data[checkList][]',this.checkList[key].description);
        })

        Object.keys(this.tasks).forEach(keys => {
            Object.keys(this.tasks[keys]).forEach(key => {
                post.append('data[tasks]['+keys+']['+key+']',this.tasks[keys][key]);
            })
        })
        axios.post(
            this.$SAMCOTEC.r_path,post
        ).then((response) => {
            if(response && response.data){
                this.$parent.getProjects()
                document.getElementById('hideAddProject').click();
            }
        })
    },
    deleteTask(index,type){
        if(type == 1)
            this.tasks.splice(index, 1);
        else if(type == 2)
            this.checkList.splice(index,1);
    },
    addToTask(){
        let employee = '';
        for(let i=0;i<this.responses.length;i++){
            if(this.responses[i].value == this.newTask.employee){
                employee = this.responses[i].text;
            }
        }
        if (this.newTask.description == "") {
            let message = "يجب ان تقوم باضافة وصف المهمة";
            this.$snotify.error(message, "تنبيه", {
            timeout: 1000000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition.centerCenter,
            buttons: [
                {
                text: this.$parent.lang.close,
                action: (toast) => {
                    this.$snotify.remove(toast.id);
                },
                },
            ],
            });
            return false;
        }
        if (this.newTask.title == "") {
            let message = "يجب إضافة عنوان المهمة";
            this.$snotify.error(message, "تنبيه", {
            timeout: 1000000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition.centerCenter,
            buttons: [
                {
                text: this.$parent.lang.close,
                action: (toast) => {
                    this.$snotify.remove(toast.id);
                },
                },
            ],
            });
            return false;
        }
        if (this.newTask.executed_date == "") {
            let message = "يجب اضافة تاريخ تنفيذ المهمة";
            this.$snotify.error(message, "تنبيه", {
            timeout: 1000000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition.centerCenter,
            buttons: [
                {
                text: this.$parent.lang.close,
                action: (toast) => {
                    this.$snotify.remove(toast.id);
                },
                },
            ],
            });
            return false;
        }
        this.tasks.push({
            description: this.newTask.description,
            agent: this.newTask.employee,
            employee: employee,
            title: this.newTask.title,
            executed_date: this.newTask.executed_date,
        })
        this.newTask = {
            employee: 1,
            description: '',
            title: '',
            executed_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        }
    },
    addToCheck(){
        if (this.nCheckList.description == "") {
            let message = "يجب اضافة وصف لقائمة التحقق";
            this.$snotify.error(message, "تنبيه", {
            timeout: 1000000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition.centerCenter,
            buttons: [
                {
                text: this.$parent.lang.close,
                action: (toast) => {
                    this.$snotify.remove(toast.id);
                },
                },
            ],
            });
            return false;
        }
        this.checkList.push({
            description: this.nCheckList.description,
        })
        this.nCheckList = {
            description: '',
        }
    },
    getUsers() {
      const post = new FormData();
      post.append("type", "getUsers");
      post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append("data[crm_id]", this.crm_id);
      axios.post(this.$SAMCOTEC.r_path, post).then((response) => {
        // console.log(response.data);
        if (response && response.data && response.data.results) {
          this.users = response.data.results.data.results;
        }
      });
    },
    getStatics() {
      const post = new FormData();
      post.append("type", "getStaticOptions");
      post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append("data[phrase]", "taskHomeStatus");
      axios.post(this.$SAMCOTEC.r_path, post).then((response) => {
        // console.log(response.data);
        if (response && response.data && response.data.results) {
          this.statuss = response.data.results.data;
        }
      });
    },
    getProjTypes() {
      const post = new FormData();
      post.append("type", "getStaticOptions");
      post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append("data[phrase]", "ProjectTypes");
      axios.post(this.$SAMCOTEC.r_path, post).then((response) => {
        // console.log(response.data);
        if (response && response.data && response.data.results) {
          this.projectsType = response.data.results.data;
        }
      });
    },
    getPriorities() {
      const post = new FormData();
      post.append("type", "getStaticOptions");
      post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append("data[phrase]", "Priorities");
      axios.post(this.$SAMCOTEC.r_path, post).then((response) => {
        // console.log(response.data);
        if (response && response.data && response.data.results) {
          this.priorities = response.data.results.data;
        }
      });
    },
    addNote() {
      if (this.notes == "") {
        let message = "يجب ان تقوم باضافة وصف المهمة";
        this.$snotify.error(message, "تنبيه", {
          timeout: 1000000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: SnotifyPosition.centerCenter,
          buttons: [
            {
              text: this.$parent.lang.close,
              action: (toast) => {
                this.$snotify.remove(toast.id);
              },
            },
          ],
        });
        return false;
      }

      const post = new FormData();
      post.append("type", "UpdateNewTaskTo");
      post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
      post.append("data[id]", this.crm_id);
      post.append("data[task_text]", this.notes);
      post.append("data[task_response]", this.task_response);
      post.append("data[status]", this.status);
      post.append("data[exeute_date]", this.exeute_date);
      post.append("data[task_priority]", this.task_priority);

      axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
        const res = response.data;
        let message = "تم تعديل المهمة بنجاح";
        this.$snotify.error(message, "تنبيه", {
          timeout: 1000000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: SnotifyPosition.centerCenter,
          buttons: [
            {
              text: this.$parent.lang.close,
              action: (toast) => {
                this.$snotify.remove(toast.id);
              },
            },
          ],
        });
        this.$parent.getCurrentCards();
        document.getElementById("hideTaskUpdate").click();
        
      });

      this.message = "";
    },
  },
  created() {
    this.getUsers();
    this.getStatics();
    this.getProjTypes();
    this.getPriorities();
  },
};
</script>
